<template>
  <div class="layout-left-side">
    <div class="logo flexCenter">
      <img
        src="@/assets/img/logo.png"
        alt=""
        class="complete"
        v-if="!collapsed"
      />
      <img src="@/assets/img/logoIcon.png" alt="" class="icon" v-else />
    </div>
    <a-menu
      theme="dark"
      mode="inline"
      @click="leftMenuClick"
      :selectedKeys="[childCurrent]"
      :open-keys.sync="openKeys"
    >
      <template v-for="item in menuList">
        <a-menu-item v-if="!item.children" :key="item.key">
          <i :class="`iconfont ${item.icon}`"></i>
          <span class="title">{{ item.title }}</span>
        </a-menu-item>
        <a-sub-menu
          v-else
          :key="item.key"
          @titleClick="titleClick(item)"
        >
          <span slot="title"><i :class="`iconfont ${item.icon}`"></i><span v-if="!collapsed">{{item.title}}</span></span>
          <a-menu-item v-for="subitem in item.children" :key="subitem.key">
            <i :class="`iconfont ${subitem.icon}`"></i>
            <span class="title">{{ subitem.title }}</span>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  name: "LayoutLeftSide",
  props: {
    collapsed: { type: Boolean, default: () => false },
    menuList: { type: Array, default: () => [] },
    current_two: { type: Object, default: () => {} },
    current_three: { type: Object, default: () => {} },
  },
  data: () => {
    return {
      childCurrent: "",
      openKeys: [],
    };
  },
  methods: {
    leftMenuClick(e) {
      console.log('leftMenuClick', e)
      console.log('left-menuList', this.menuList)
      console.log('left-current_two', this.current_two)
      this.childCurrent = e.key;
      this.menuList.forEach((item, index) => {
        if (item.children) {
          item.children.forEach((item_three) => {
            if (item_three.key === e.key) {
              this.$router.push(item_three.path);
            }
          });
        } else {
          if (item.key === e.key) {
            const value = this.menuList[index];
            this.$router.push(value.path);
          }
        }
      });
    },
    titleClick(path) {
      console.log('titleClick', path)
    },
    getOpenKeys() {
      // 自动展开subMenu
      this.openKeys = [];
      if(this.current_three&&this.current_three.key) {
        let key = this.current_three.key.substr(0,3);
        this.openKeys.push(key);
      }      
    }
  },
  watch: {
    current_three: {
      handler() {
        if(this.current_three) {
          this.childCurrent = this.current_three.key || "";
        } else if(this.current_two) {
          this.childCurrent = this.current_two.key || "";
        } else {
          this.childCurrent = "";
        }
        
        this.getOpenKeys();        
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 64px;
  .complete {
    width: 130px;
  }
  .icon {
    width: 30px;
  }
}
.iconfont {
  margin-right: 6px;
}
</style>
