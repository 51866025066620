<template>
  <div :class="showNav?'loginPage':'loginPageModify'">
    <div class="content">
      <div class="headerLogo">
        <img src="@/assets/img/loginLogo.png" alt="" />
      </div>
      <div class="pageTitle">{{ title }}</div>
      <a-form-model
        id="components-form-demo-normal-login"
        class="loginForm"
        ref="pwdForm"
        :rules="rules"
        :model="formData"
      >
        <a-form-model-item prop="phoneNumber">
          <a-input
            name="phone"
            type="text"
            v-model="formData.phoneNumber"
            @keyup.enter="handleSubmit"
            placeholder="请输入手机号"
            :disabled="!canChangeAccount"
          >
            <i slot="prefix" class="iconfont icon-shouji icon"></i>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input
            name="psd"
            type="password"
            v-model="formData.password"
            @keyup.enter="handleSubmit"
            placeholder="请输入密码"
            autocomplete="new-password"
          >
            <a-icon slot="prefix" type="lock" class="icon" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="confirmPsw">
          <a-input
            name="tpsd"
            type="password"
            v-model="formData.confirmPsw"
            placeholder="请确认密码"
          >
            <a-icon slot="prefix" type="lock" class="icon" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="captcha">
          <a-input
            name="picCode"
            v-model="formData.captcha"
            type="text"
            @keyup.enter="handleSubmit"
            placeholder="图形验证码"
            class="inlineBlock"
          >
            <i slot="prefix" class="iconfont icon-ic_image icon"></i>
          </a-input>
          <a class="captcha" @click="getPictureCode" title="单击刷新图形验证码">
            <a-spin :spinning="pictureCtrl.loading">
              <img :src="pictureCtrl.data" v-show="pictureCtrl.data" />
            </a-spin>
          </a>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input
            name="code"
            type="text"
            v-model="formData.code"
            @keyup.enter="handleSubmit"
            placeholder="验证码"
            class="inlineBlock"
          >
            <i slot="prefix" class="iconfont icon-duanxin icon"></i>
          </a-input>
          <a-button type="primary" @click="getVerificationCode" class="getCode">
            {{ smsCtrl.buttonText }}
          </a-button>
        </a-form-model-item>
        <div class="routerNav" v-if="showNav">
          <router-link to="/login">去登录</router-link>
          <router-link to="/register">立即注册</router-link>
        </div>
        <a-form-model-item>
          <a-button type="primary" @click="handleSubmit" class="registerBtn">
            确定
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer" v-if="showNav">
      <img src="@/assets/img/footLogo.png" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { isTelephone } from "@/utils/tools";
import {
  getVerifyCodeAsync,
  sendMobileCodeAsync,
  resetPasswordAsync,
} from "@/api/account";
export default {
  name: "UpdatePwContent",
  props: {
    showNav: { type: Boolean, default: () => true },
    title: { type: String, default: () => "修改密码" },
    canChangeAccount: { type: Boolean, default: () => true },
  },
  data() {
    let isPhone = (rule, value, callback) => {
      isTelephone(rule, value, callback);
    };
    return {
      formData: {},
      smsCode: "", //页面缓存验证码
      rules: {
        phoneNumber: [{ validator: isPhone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码" }],
        confirmPsw: [
          {
            required: true,
            validator: (rule, value, cbfn) => {
              if (!value) cbfn("请输入确认密码");
              if (this.formData.password !== value) cbfn("两次密码输入不相同");
              cbfn();
            },
          },
        ],
        captcha: [{ required: true, message: "请输入验证码" }],
        code: [{ required: true, message: "请输入验证码" }],
      },
      pictureCtrl: {
        data: null,
        loading: false,
      },
      smsCtrl: {
        lastSendAt: null,
        loading: false,
        canSendSms: true,
        tick: 60,
        defaultButtonText: "获取验证码",
        buttonText: "获取验证码",
        interval: 0,
      },
    };
  },
  methods: {
    //回车键确认

    // 表单提交
    handleSubmit() {
      const form = this.$refs.pwdForm;
      if (!form) {
        return console.warn("无法找到引用组件 pwdForm");
      }
      if (this.formData.password !== this.formData.confirmPsw) {
        //return this.$message.error("两次输入的密码不一致~!");
      }
      form.validate((valid) => {
        if (valid) {
          const f = this.formData;
          resetPasswordAsync({
            userName: f.phoneNumber,
            phoneNumber: f.phoneNumber,
            verifyCode: f.code,
            password: f.password,
          })
            .then((res) => {
              this.$message.success(`操作成功!`);
              if (this.showNav) {
                this.$router.push("/");
              } else {
                this.formData = {};
                this.$emit("cancel");
              }
            })
            .catch((err) => {
              if (err && typeof err === "string") {
                this.$message.error(`${err}`);
              } else {
                this.$message.error(`操作失败,请重试!`);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /** 获取验证码 */
    getVerificationCode() {
      const form = this.formData;
      if (!this.smsCtrl.canSendSms) return this.$message.warn("请耐心等待~");
      if (form.phoneNumber && form.captcha) {
        this.smsCtrl.lastSendAt = new Date();
        this.smsCtrl.loading = true;

        var parms = {
          phoneNumber: form.phoneNumber,
          verifyCode: form.captcha,
        };
        sendMobileCodeAsync(parms)
          .then((res) => {
            this.smsCtrl.loading = false;
            this.$message.success("已发送短信验证码,请在2分钟内使用！");
            this.smsCtrl.canSendSms = false;
            this.smsCtrl.tick = 120;
            this.smsCtrl.buttonText = "120s";
            this.smsCtrl.interval = setInterval(() => {
              if (this.smsCtrl.tick-- <= 0) {
                this.smsCtrl.buttonText = this.smsCtrl.defaultButtonText;
                this.smsCtrl.canSendSms = true;
                window.clearInterval(this.smsCtrl.interval);
              } else {
                this.smsCtrl.buttonText = `${this.smsCtrl.tick}s`;
              }
            }, 1000);
          })
          .catch((err) => {
            this.$message.error(err);
            this.smsCtrl.loading = false;
            this.smsCtrl.canSendSms = true;
          });
      } else {
        this.$message.error("请输入手机号以及图形验证码!");
      }
    },
    getPictureCode() {
      this.pictureCtrl.loading = true;
      getVerifyCodeAsync({ _h: true })
        .then((res) => {
          this.pictureCtrl.data = res.dataUrl;
          this.pictureCtrl.loading = false;
        })
        .catch((ex) => {
          this.pictureCtrl.data = null;
          this.pictureCtrl.loading = false;
        });
    },
  },
  created() {
    this.getPictureCode();
    this.$nextTick(() => {
      if (!this.canChangeAccount) {
        this.formData.phoneNumber = this.$store.getters.getUserInfo.phoneNumber;
        this.$forceUpdate();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_login.scss";

@media screen  and (max-width:1440px){
  .loginPage .content{
    right: 100px;
    top: 10px;
  }

  .loginPage .content .pageTitle{
    margin: 25px auto 0;
  }
}

.loginPageModify .content {
  position: initial;
}
</style>
