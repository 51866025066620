/*
{
  title:String,
  key:String,
  icon:String, 可选
  children:Array,可选
  permission:String, 可选  权限名称，与store.getUserInfo.permissions联合检查是否显示菜单
}
*/

const menuList = [
  {
    title: "采购管理",
    key: "3",
    path: "",
    children: [],
    meta: {
      src: process.env.VUE_APP_API_CloudFactory,
      target: "open",
    },
  },
  {
    title: "生产管理",
    key: "5",
    children: [],
    meta: {
      src: process.env.VUE_APP_API_xiaochilun,
      target: "open",
    },
  },
  {
    title: "产品研发",
    key: "6",
    redirect: "/projectHome",
    children: [
      {
        title: "项目管理",
        path: "",
        key: "6.1",
        icon: "icon-gongyi",
        children: [
          {
            title: "项目看板",
            path: "/projectHome", //此处不要设置权限！以免出现错误
            key: "6.1.1",
            icon: "icon-kanban",
          },
          {
            title: "项目列表",
            path: "/projectManage",
            key: "6.1.2",
            icon: "icon-guanli",
          },
          {
            title: "项目模板",
            path: "/ProjectTemplates",
            key: "6.1.3",
            icon: "icon-ic_temp",
          },
        ],
      },
      {
        title: "产品管理",
        path: "",
        key: "6.2",
        icon: "icon-gongsiguanli",
        children: [
          {
            title: "物料列表",
            path: "/MaterialManagement",
            key: "6.2.1",
            icon: "icon-ic_temp",
          },
          {
            title: "图文档列表",
            path: "/FigureDocManage",
            key: "6.2.2",
            icon: "icon-tuwenxiangqing",
          },
          {
            title: "BOM管理",
            path: "/BOMManage",
            key: "6.2.3",
            icon: "icon-luxian",
          },
        ],
      },
    ],
  },
  {
    title: "基础设置",
    key: "8",
    redirect: "/info",
    children: [
      {
        title: "基础信息",
        path: "",
        key: "8.1",
        icon: "icon-gongyi",
        children: [
          {
            title: "个人中心",
            path: "/info",
            key: "8.1.1",
            icon: "icon-icon_pc",
          },
          {
            title: "公司管理",
            path: "/companyManage",
            key: "8.1.2",
            icon: "icon-gongsiguanli",
          },
        ],
      },
    ],
  },
];

export default menuList;
