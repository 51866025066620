<template>
  <a-modal
    class="loginPage"
    :visible="visible"
    :mask="true"
    :maskClosable="false"
    :title="null"
    :footer="null"
    :destroyOnClose="true"
    @cancel="$emit('cancel')"
  >
    <PwdContent
      :showNav="false"
      :title="'修改密码'"
      :canChangeAccount="false"
      id="myPwdContent"
      @cancel="$emit('cancel')"
    />
  </a-modal>
</template>
<script>
import PwdContent from "./components/PwdContent.vue";
export default {
  props: {
    visible: { type: Boolean, default: () => false },
  },
  components: {
    PwdContent,
  },
};
</script>
<style scoped>
#myPwdContent {
  position: relative;
  top: unset;
  right: unset;
  left: unset;
  transform: unset;
}
.ant-input {
  padding-left: 1em;
}
</style>
