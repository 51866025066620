<template>
  <a-modal
    :visible="visible"
    title="查看通知"
    @cancel="$emit('cancel')"
    :footer="null"
  >
    <NotificationPanel :formId="formId" :isModal="true" />
  </a-modal>
</template>

<script>
import NotificationPanel from "@/components/Hubs/NotificationPanel";
export default {
  name: "NotificationDialog",
  components: {
    NotificationPanel,
  },
  props: {
    visible: { type: Boolean, default: () => false },
    formId: { type: Number, default: () => 0 },
  },
};
</script>

<style></style>
