/* eslint-disable no-unused-vars */
import menuList from "./menu";
import { routeList } from "./index";

/**
 * 用路径查找子级菜单
 * @param {String} path 路由路径
 * @param {*} children 子级菜单集合
 * @returns 找到的菜单集合
 */
export const findChildMenuByPath = (path, children) => {
  children = children || [];
  if (children.length === 0) return null;
  path = path.toLowerCase();
  let rt = children.find((x) => x.path && x.path.toLowerCase() === path);
  if (rt) return [rt];
  const next = [];
  children.forEach((x) => {
    next.push(...(x.children || []));
  });
  rt = findChildMenuByPath(path, next);
  if (rt && rt.length) {
    const child = rt[0];
    const parent = children.find(
      (x) => x.children && x.children.indexOf(child) >= 0
    );
    return [parent, ...rt];
  }
  return rt || [];
};

/**
 * 获取默认左侧菜单
 * @returns 左侧菜单项
 */
export const getDefaultLeftMenu = (current) => {
  const menu = menuList.find((x) => {
    return x.key === current;
  });
  return menu || menuList[0];
};

/**
 * 以路径查找菜单
 * @param {*} path 路由路径
 * @returns [0:当前菜单,1:子级菜单]
 */
export const findMenuByPath = (path) => {
  const menu = findChildMenuByPath(path, menuList);
  return menu || [getDefaultLeftMenu()];
};

const findParentRouterByPath = (path, root) => {
  const list = root.children;
  if (!list) return null;
  path = path.toLowerCase();
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element.path && element.path.toLowerCase() === path) return root;
    if (element.children && element.children.length) {
      const p = findParentRouterByPath(path, element);
      if (p) {
        return p;
      }
    }
  }
  return null;
};

/**
 * 查找上级路由
 * @param {*} path 路由路径
 */
export const findParentRouter = (path) => {
  return findParentRouterByPath(path, { children: routeList });
};
