<template>
  <a-layout id="components-layout-demo-side" class="layoutBox">
    <a-layout-sider v-model="collapsed" collapsible ref="leftBar" @collapse="onCollapse">
      <LeftSide
        ref="refLeft"
        :menuList="subMenuList"
        :current_two="subMenu"
        :current_three="subMenu_three"
        :collapsed="collapsed"
      />
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="layoutHeader">
        <HeaderSide
          ref="refHeader"
          :menuList="menuList"
          @initChildMenuList="whenInitSubMenuList"
          @initChildMenu="whenInitSubMenu"
          @initChildMenu_three="whenInitChildMenu_three"
          @triggerLeft="onTriggerLeft"
        />
      </a-layout-header>
      <a-layout-content class="content">
        <Content />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import LeftSide from "./Left.vue";
import HeaderSide from "./Header.vue";
import Content from "./Content.vue";
import menuList from "@/router/menu";
import initMobile from "@/utils/mobileHelper";
export default {
  name: "LeftTopContentLayout",
  components: {
    LeftSide,
    HeaderSide,
    Content,
  },
  data() {
    return {
      collapsed: false,
      subMenuList: [],    // 6.1 6.2
      subMenu: {},        // 6.2
      subMenu_three: {},  // 6.2.3
      // meun菜单
      menuList: [],
    };
  },
  created() {
    initMobile();
    this.filterMenus(menuList);
  },
  methods: {
    onCollapse(collapsed, type) {
      console.log('onCollapse', collapsed, type)
      if(collapsed) {
        this.$refs.refLeft.openKeys = [];
      } else {
        this.$refs.refLeft.getOpenKeys();
      }
    },
    whenInitSubMenuList(menuList) {
      console.log('initChildMenuList', menuList);
      this.subMenuList = menuList;
    },
    whenInitSubMenu(menu) {
      console.log('initChildMenu', menu)
      this.subMenu = menu;
    },
    whenInitChildMenu_three(meun_three) {
      console.log('initChildMenu_three', meun_three)
      this.subMenu_three = meun_three;
    },
    onTriggerLeft(menu_current) {
      console.log('onTriggerLeft', menu_current)
      this.$nextTick(() => {
        menu_current&&this.$refs.refLeft.leftMenuClick(menu_current);
      });      
    },
    hasPermission(menu) {
      const permissions = this.currentUser.permissions ?? [];
      var requiredPermission = menu.permission;
      if (requiredPermission) {
        //需要权限
        if (permissions.indexOf(requiredPermission) === -1) {
          //无权限
          return false;
        }
      }
      return true;
    },
    filterMenus(menulist) {
      let tempMenu = [];
      const filterMenu = (menu) => {
        if (!this.hasPermission(menu)) {
          return null;
        }
        if (menu.children) {
          for (let i = menu.children.length - 1; i >= 0; i--) {
            if (!this.hasPermission(menu.children[i])) {
              //没有权限则移除
              menu.children.splice(i, 1);
            }
          }
        }
        return menu;
      };
      menulist.forEach((item) => {
        let menu = filterMenu(item);
        if (menu) {
          tempMenu.push(menu);
        }
      });
      this.menuList = tempMenu;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserInfo || {};
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.layoutBox {
  height: calc(100vh - 7px);
  /* 2021-06-28 从1200px 调整为1000px，如果后期有兼容性问题可以试着改回 */
  min-width: 1000px;
  .title {
    padding-left: 5px;
  }
}
.content {
  // background: #fff;
  // padding: 10px 20px 20px 20px;
  min-height: 280px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
}
.layoutHeader {
  padding: 0 20px 0 0;
}
</style>
