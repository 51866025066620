/* eslint-disable no-unused-vars */
import isMobile from "ismobilejs";
const _IsMobile = isMobile(window.navigator).any;
const getVisibleWidth = () => {
  const expired = document.body.style.width;
  document.body.style.width = "100vw";
  const width = parseFloat(document.body.scrollWidth);
  document.body.style.width = expired;
  return width;
};
export default function initMobile(minWidth = 1000) {
  if (_IsMobile) {
    document.body.classList = `${document.body.classList.value} mobile`;
    // const vw = getVisibleWidth();
    // const scale = vw > minWidth ? 0 : vw / minWidth;
    // let currentStyle = document.body.getAttribute("style");
    // // const newWidth = minWidth / scale;
    // if (scale > 0) {
    //   const transform = `transform:scale(${scale},${scale});`;
    //   const width = `width:${minWidth}px;`;
    //   const marginPx = (scale - 1) * vw;
    //   const margin = `margin-left:${marginPx}px`;
    //   const newStyle = `${transform}${width}${margin}`;
    //   if (currentStyle) {
    //     if (/;$/.test(currentStyle))
    //       currentStyle = `${currentStyle}${newStyle}`;
    //     else currentStyle = `${currentStyle};${newStyle}`;
    //   } else {
    //     currentStyle = newStyle;
    //   }
    //   document.body.setAttribute("style", currentStyle);
    // }
  }
}
