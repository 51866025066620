<template>
  <LeftTopContentLayout />
</template>

<script>
import LeftTopContentLayout from "./LeftTopContent/Index";
export default {
  name: "Layout",
  components: { LeftTopContentLayout },
};
</script>

<style></style>
