<template>
  <div class="layout-header">
    <!-- @click="headerMenuClick" -->
    <a-menu
      theme="dark"
      mode="horizontal"
      class="menuLine"
      :selectedKeys="[menuCtrl.current]"
    >
      <a-menu-item
        v-for="item in menuList"
        :key="item.key"
        @click="onTopMenuClick(item)"
      >
        {{ item.title }}
      </a-menu-item>
    </a-menu>
    <div class="headRight">
      <a-spin :spinning="personalCtrl.loading">
        <a
          href="#"
          class="notification-bell"
          @click="whenShowNotify"
          title="通知"
        >
          <a-badge :count="notifyCtrl.count" :showZero="false">
            <a-icon
              type="bell"
              :style="{ color: '#ffffff', fontSize: '24px' }"
            />
          </a-badge>
        </a>
        <a-dropdown v-model="companyCtrl.companyVisible">
          <a class="companyName" @click="(e) => e.preventDefault()">
            {{ currentUser.companyName }}
            <a-icon type="down" />
          </a>
          <template #overlay>
            <a-menu @click="handlePersonMenuClick" class="func">
              <a-menu-item
                v-for="(item, index) in currentUser.companyList"
                :key="`company-${index}`"
                @click="whenSwitchCompany(item)"
                :disabled="isCurrentCompany(item)"
              >
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown
          v-model="personalCtrl.userVisible"
          :title="currentUser.name"
        >
          <a class="avatar">
            <img
              src="@/assets/img/user.png"
              @click="(e) => e.preventDefault()"
              :alt="currentUser.name"
            />
            <p style="display: none">{{ currentUser.name }}</p>
          </a>
          <template #overlay>
            <a-menu @click="handlePersonMenuClick" class="func">
              <a-menu-item v-for="item in funMenuList" :key="item.key">
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-spin>
    </div>
    <NotificationDialog
      v-if="notifyCtrl.visible"
      :visible="notifyCtrl.visible"
      :formId="notifyCtrl.formId"
      @cancel="notifyCtrl.visible = false"
    />
    <UpdatePswDialog
      v-if="pwdCtrl.visible"
      :visible="pwdCtrl.visible"
      @cancel="pwdCtrl.visible = false"
    />
  </div>
</template>

<script>
import NotificationDialog from "@/components/Hubs/NotificationDialog";
import UpdatePswDialog from "@/views/Login/UpdatePswDialog";
import notification from "./HeaderNotification";
import { switchCompanyAsync, logoutAsync } from "@/api/account";
import store from "@/store";
import {
  findMenuByPath,
  getDefaultLeftMenu,
  findChildMenuByPath,
} from "@/router/helper";
export default {
  name: "layoutHeader",
  props: {
    menuList: { type: Array, default: () => [] },
  },
  components: {
    NotificationDialog,
    UpdatePswDialog,
  },
  data: () => {
    return {
      funMenuList: [
        { key: 1, name: "个人中心" },
        { key: 2, name: "修改密码" },
        { key: 3, name: "退出登录" },
      ],
      notifyCtrl: {
        visible: false,
        formId: 0,
        count: undefined,
        basicCount: 0,
        data: [],
      },
      pwdCtrl: {
        visible: false,
      },
      companyCtrl: {
        companyVisible: false,
      },
      personalCtrl: {
        userVisible: false,
        loading: false,
      },
      menuCtrl: {
        current: "1",
        selectedList: [], // 6.1 6.2
        currentMenu: {}, // 6
        childMenu: {}, // 6.2
        current_three: {}, // 6.2.3
      },
    };
  },
  created() {
    this.initMenu();
    this.registHubAsync();
  },
  methods: {
    ...notification.methods,
    handlePersonMenuClick(e) {
      let url;
      switch (e.key) {
        case 1: // 个人中心
          url = "/info";
          break;
        case 2: // 修改密码
          // url = "/updatePsw";
          this.pwdCtrl.visible = true;
          return;
        case 3: // 退出登录
          this.$store.dispatch("logout", true);
          return;
        default:
          break;
      }
      this.$router.push(url);
    },
    whenSwitchCompany(item) {
      if (this.$store.getters.getUserInfo.companyId == null) {
        item.setDefaule = true;
      }
      this.personalCtrl.loading = true;
      switchCompanyAsync(item)
        .then(() => {
          this.personalCtrl.loading = false;
          this.$message.success("切换成功～！");
        })
        .catch(() => {
          this.personalCtrl.loading = false;
          this.$message.error("切换失败，请重新登录或尝试其他公司～！");
        });
    },
    onTopMenuClick(menu) {
      console.log("--onTopMenuClick--meun", menu);
      if (this.current === menu.key) return;
      console.log("onTopMenuClick");
      this.current = menu.key;
      this.menuCtrl.current = this.current;
      this.menuCtrl.selectedList = menu.children || [];
      this.menuCtrl.childMenu = (menu.children && menu.children[0]) || {};
      this.menuCtrl.current_three =
        (this.menuCtrl.childMenu &&
          this.menuCtrl.childMenu.children &&
          menu.children[0].children[0]) ||
        {};
      if (menu.meta && menu.meta.src) {
        switch (menu.meta.target) {
          case "open":
            window.open(menu.meta.src);
        }
      }
      // 默认选择第一个子菜单
      if (this.menuCtrl.current_three.key) {
        this.$emit("triggerLeft", this.menuCtrl.current_three);
      } else if (this.menuCtrl.childMenu.key) {
        // 左侧只有二级，没有三级
        this.$emit("triggerLeft", this.menuCtrl.childMenu);
      }
    },
    initMenu() {
      let path = this.$route.path;
      let menu = findMenuByPath(path);
      const setMenu = (menus) => {
        const currentMenu = (this.menuCtrl.currentMenu = menus[0]);
        const children = currentMenu.children || [];
        this.menuCtrl.childMenu =
          menus[1] || findChildMenuByPath(path, children)[0] || children[0];
        this.menuCtrl.current_three = menus[2];
        this.menuCtrl.current = currentMenu.key;
        this.menuCtrl.selectedList = children;

        console.log("initMenu", this.menuCtrl);
      };
      if (menu && menu.length) {
        setMenu(menu);
      } else if (this.$route && this.$route.matched) {
        for (let index = this.$route.matched.length - 1; index >= 0; index--) {
          const element = this.$route.matched[index];
          menu = findMenuByPath(element.path);
          if (menu && menu.length) {
            setMenu([...menu, { path: this.$route.path }]);
            break;
          }
        }
      }
    },
    headerMenuClick(e) {
      if (this.current === e.key) return;
      this.current = e.key;
      const menu = getDefaultLeftMenu();
      const leftMenu = menu.children[0];
      if (leftMenu) {
        this.leftMenuClick(leftMenu);
      }
    },
    isCurrentCompany(item) {
      return item.id === this.currentUser.companyId;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserInfo || {};
    },
  },
  watch: {
    ...notification.watch,
    "menuCtrl.selectedList": {
      handler() {
        this.$emit("initChildMenuList", this.menuCtrl.selectedList);
      },
      deep: true,
      immediate: true,
    },
    "menuCtrl.childMenu": {
      handler() {
        this.$emit("initChildMenu", this.menuCtrl.childMenu);
      },
      deep: true,
      immediate: true,
    },
    "menuCtrl.current_three": {
      handler() {
        this.$emit("initChildMenu_three", this.menuCtrl.current_three);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .menuLine {
    line-height: 64px;
  }
  .headRight {
    display: flex;
    align-items: center;
    .companyName {
      color: #fff;
      padding-right: 10px;
    }
    .func {
      padding: 6px 10px;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    > * {
      margin-right: 2em;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
