<template>
  <div class="notify-panel">
    <a-spin :spinning="loading">
      <a-card title="通知" :bordered="false" v-if="isModal === false">
        <a-list item-layout="horizontal" :data-source="dataSource">
          <a-list-item
            slot="renderItem"
            slot-scope="item, index"
            :id="`notification-${index}`"
            :class="item.readState === 1 ? 'unread-notify' : 'readed-notify'"
          >
            <a-list-item-meta
              @click="handleRead(item)"
              :description="item.data.properties.message"
            >
              <a slot="title" :href="item.url">
                <span> {{ item.data.properties.title }} </span>
                <sub style="margin-left: 2em">
                  {{ formatDate(item.creationTime) }}
                </sub>
              </a>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </a-card>
      <a-list item-layout="horizontal" :data-source="dataSource" v-else>
        <a-spin :spinning="loadingConfirm">
          <a-button
            type="default"
            @click="handleReadAll()"
            v-show="dataSource.length > 0"
          >
            全部已读
          </a-button>
        </a-spin>
        <a-list-item
          slot="renderItem"
          slot-scope="item, index"
          :id="`notification-${index}`"
          :class="item.readState === 1 ? 'unread-notify' : 'readed-notify'"
        >
          <a-list-item-meta
            @click="handleRead(item)"
            :description="item.data.properties.message"
          >
            <a slot="title" :href="item.url">
              <span>{{ item.data.properties.title }}</span>
              <sub style="margin-left: 2em">
                {{ formatDate(item.creationTime) }}
              </sub>
            </a>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-spin>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import moment from "moment";
import * as Linq from "linq-es2015";
export default {
  props: {
    isModal: { type: Boolean, default: () => false },
  },
  data() {
    return {
      dataSource: [],
      loadingConfirm: false,
      loading: false,
    };
  },
  created() {
    this.$notificationHub.onChange(() => {
      this.update();
    });
    this.$nextTick(() => {
      this.update();
    });
  },
  methods: {
    gotodetail(item) {
      console.log("gotodetail", item.data);
      if (item.data.properties.orderId) {
        // 关闭消息框
        this.putOrderDetails(item.data.properties.orderId); // TODO 跳转链接
      }
    },
    putOrderDetails(id) {
      // this.$utils.storageSet('TradeDetails', model);
      this.$router.push({ path: "/trade/TradeDetails?traderId=" + id });
    },
    update() {
      this.loading = true;
      const hub = this.$notificationHub.hub;
      if (
        hub === null ||
        hub === undefined ||
        hub.connectionState !== "Connected"
      ) {
        return;
      }
      if (this.isModal) {
        this.$notificationHub.getNotificationAsync(1).then((res) => {
          this.dataSource = res.items;
          console.log("aaaaaaaa", this.dataSource);
          this.loading = false;
        });
      } else {
        this.$notificationHub.getNotificationAsync(2).then((res) => {
          this.dataSource = res.items;
          console.log("bbbbbbbbbb", this.dataSource);
          this.loading = false;
        });
      }
    },
    formatDate(input) {
      const dt = new Date(input);
      return moment(dt).format("YYYY-MM-DD HH:mm:ss");
    },
    handleReadAll() {
      this.$confirm({
        title: "警告",
        content: `真的要将所有（含未显示的）通知设置为已读吗?`,
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.handleRead({ id: "-1" });
        },
        onCancel: function () {
          console.log("cancel delete");
        },
      });
    },
    handleRead(item) {
      let gotoDetail = true;
      const d = this.dataSource || [];
      if (item.id === "-1") {
        gotoDetail = false;
        for (let i = 0; i < d.length; i++) {
          const fd = d[i];
          fd.readState = 0;
        }
      } else {
        const fd = Linq.asEnumerable(d).FirstOrDefault((x) => x.id === item.id);
        if (fd) {
          if (fd.readState === 0) {
            this.gotodetail(item);
            return;
          }
          fd.readState = 0;
        }
      }
      this.loadingConfirm = true;
      this.$notificationHub.changeStateAsync(item.id, 0).then((res) => {
        this.loadingConfirm = false;
        this.$notificationHub.triggerChange();
        if (gotoDetail) {
          this.gotodetail(item);
        }
        this.$emit("change");
      });
    },
  },
  watch: {},
};
</script>

<style>
.notify-panel .readed-notify * {
  color: #ccc;
}
.notify-panel .unread-notify * {
  color: #676;
}
.notify-panel .unread-notify:hover,
.notify-panel .readed-notify:hover {
  opacity: 0.9;
}
</style>
