import * as Linq from "linq-es2015";
import storage from "store";
export default {
  methods: {
    whenShowNotify() {
      this.notifyCtrl.visible = true;
      this.notifyCtrl.formId = new Date().valueOf;
    },
    registHubAsync() {
      const hub = this.$notificationHub;
      hub.init();
      const self = this;
      hub.on("getNotification", (model) => {
        self.getNotification(model);
      });
      hub.onChange(() => {
        hub.getNotificationAsync(1).then((res) => {
          this.notifyCtrl.basicCount = 0;
          this.notifyCtrl.data = res.items;
        });
      });
      hub.start().then(() => {
        hub.getNotificationAsync(1).then((res) => {
          this.notifyCtrl.basicCount = 0;
          this.notifyCtrl.data = res.items;
        });
      });
    },
    getNotification(model) {
      // 所有从 getNotification 接口过来的数据都是未读的
      model.readState = 1;
      this.notifyCtrl.data.splice(0, 0, model);
      const properties = model.data.properties;
      this.$notification.warning({
        description: properties.message,
        message: properties.title,
      });
    },
  },
  watch: {
    "notifyCtrl.data": {
      handler() {
        const unreadCount = Linq.asEnumerable(this.notifyCtrl.data)
          .Where((x) => x.readState === 1)
          .Count();
        this.notifyCtrl.count = this.notifyCtrl.basicCount + unreadCount;
        if (this.notifyCtrl.count > 0) {
          console.log("您有新通知", new Date());
        }
        storage.set("notification", this.notifyCtrl.data);
        this.notifyCtrl.formId = new Date().valueOf();
      },
      deep: true,
      immediate: true,
    },
  },
};
